<template>
  <div class="g101-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">刷新</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      class="mt-3"
      :data="meterageDateStats"
      border
      header-cell-class-name="bg-info text-light"
      :height="height">
      <el-table-column fixed="left" type="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="meterageDisplayCode" width="100" header-align="center" align="center" label="子目号"></el-table-column>
      <el-table-column prop="meterageName" header-align="center" align="center" label="子目名称"></el-table-column>
      <el-table-column prop="unit" width="60" header-align="center" align="center" label="单位"></el-table-column>
      <el-table-column prop="pictureNum" width="100" header-align="center" align="center" label="清单数量"></el-table-column>
      <el-table-column prop="price" width="100" header-align="center" align="center" label="单价"></el-table-column>
      <el-table-column prop="pictureAmount" width="120" header-align="center" align="center" label="金额"></el-table-column>
      <el-table-column prop="currentMonthPlanNum" width="120" header-align="center" align="center" label="本月计划数量"></el-table-column>
      <el-table-column prop="currentDateStatNum" width="120" header-align="center" align="center" label="本日完成数量"></el-table-column>
      <el-table-column fixed="right" width="50" label="操作" header-align="center" align="center">
        <template slot-scope="scope">
          <i @click="handleEditClick(scope.row)" class="text-info far fa-edit operation-button"></i>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog
      ref="myDialog"
      title="编辑数量"
      size="md"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleOk"
      >
      <el-form v-if="meterageDateStat" :model="meterageDateStat" label-width="120px">
        <el-form-item label="项目编码">
          <el-input disabled v-model="meterageDateStat.meterageDisplayCode"></el-input>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input disabled v-model="meterageDateStat.meterageName"></el-input>
        </el-form-item>
        <el-form-item label="本月计划数量">
          <el-input disabled v-model="meterageDateStat.currentMonthPlanNum"></el-input>
        </el-form-item>
        <el-form-item label="本日完成数量">
          <el-input v-model="meterageDateStat.currentDateStatNum"></el-input>
        </el-form-item>
      </el-form>
    </my-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import utility from '@/common/utility'
import { mapGetters } from 'vuex'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'

export default {
  name: 'G101Index',
  components: {
    MySearchBar,
    MyDialog
  },
  data () {
    return {
      meterageDateStats: [],
      meterageDateStat: null,
      reportDate: ''
    }
  },
  computed: {
    height () {
      return document.body.clientHeight - 170
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      month: 'getMonth',
      date: 'getDate',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    handleSearchClick () {
      this.getData()
    },
    handleEditClick (row) {
      this.meterageDateStat = {
        guid: utility.getUuid(),
        meterageDisplayCode: row.meterageDisplayCode,
        meterageName: row.meterageName,
        meterageListGuid: row.meterageListGuid,
        currentDateStatNum: row.currentDateStatNum,
        year: 0,
        month: 0,
        date: 0
      }
      this.$refs.myDialog.open()
    },
    handleOk () {
      if (!this.meterageDateStat.currentDateStatNum || isNaN(this.meterageDateStat.currentDateStatNum)) {
        this.$message({
          type: 'warning',
          message: '请检查您的输入！'
        })
        return
      }
      this.meterageDateStat.currentDateStatNum = parseFloat(this.meterageDateStat.currentDateStatNum)
      axios.post(utility.getRestFullUrl('meterageDateStat'), this.meterageDateStat, {
        params: {
          tenderGuid: this.tenderGuid,
          reportDate: this.reportDate
        }
      })
        .then(res => {
          this.$refs.myDialog.close()
          if (res.data.code === 1) {
            this.meterageDateStats.find(item => item.meterageListGuid === this.meterageDateStat.meterageListGuid).currentDateStatNum = this.meterageDateStat.currentDateStatNum
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getData () {
      axios.get(utility.getRestFullUrl('meterageDateStat'), {
        params: {
          tenderGuid: this.tenderGuid,
          reportDate: this.reportDate
        }
      })
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.meterageDateStats = res.data.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  created () {
    this.reportDate = moment([ this.year, this.month - 1, this.date ]).format('YYYYMMDD')
    this.getData()
  }
}
</script>

<style scoped lang="scss">
</style>
